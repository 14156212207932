import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const BoxCitation = styled.div`
  padding: 24px 20px;

  img {
    width: 24px;
    height: 24px;
    max-width: 24px;

    @media (min-width: ${breakpoints.md}) {
      width: 40px;
      height: 40px;
      max-width: 40px;
    }
  }

  > div:first-child {
    align-items: start;
    display: flex;
  }

  > div:last-child {
    margin-left: 48px;
    @media (min-width: ${breakpoints.md}) {
      height: 40px;
      margin-left: 63px;
    }
  }
`
