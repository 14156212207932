import React, { useState } from 'react'

import FullScreenModal from 'src/components/FullScreenModal'
import ModalNewsletter from 'src/components/Newsletter/Modal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Container } from './style'

type InternalNewsletterProps = {
  page?: string
  category?: string
  subCategory?: string
}

const InternalNewsletter = ({ page, category, subCategory }: InternalNewsletterProps) => {
  const [open, setOpen] = useState(false)
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <>
      <Container>
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-6 col-lg-5">
              <p className="fs-12 lh-15 fs-md-16 lh-md-20 text-grayscale--500 fw-600 mb-0">
                Receba nossas análises por e-mail
              </p>
            </div>
            <div className="col-6 col-md-4 col-xl-3">
              <button
                onClick={() => {
                  setOpen(true)
                  sendDatalayerEvent({
                    section: 'Receba nossas análises por e-mail',
                    element_action: 'click button',
                    element_name: 'Cadastrar',
                    section_name: 'Receba nossas análises por e-mail',
                  })
                }}
                type="button"
              >
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </Container>
      <FullScreenModal open={open} setOpen={setOpen} padding>
        <ModalNewsletter
          setOpen={setOpen}
          page={page}
          category={category}
          subCategory={subCategory}
        />
      </FullScreenModal>
    </>
  )
}

export default InternalNewsletter
