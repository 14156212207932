import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export default styled.figure`
  padding: 0;
  margin: 0 auto;

  iframe {
    min-height: 210px;

    @media (min-width: ${breakpoints.md}) {
      min-height: 360px;
    }

    @media (min-width: ${breakpoints.lg}) {
      min-height: 430px;
    }
  }
`
