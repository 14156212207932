import React, { useState, memo, ReactNode, MouseEvent } from 'react'

import { SumaryDetailsSection } from './style'

interface ISummaryDetails {
  summary: string
  children: ReactNode
}

const SummaryDetails = ({ summary, children }: ISummaryDetails) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggle(evt: MouseEvent) {
    if ((evt.target as HTMLElement).tagName !== 'A') {
      evt && evt.preventDefault()
      setIsOpen(!isOpen)
    }
  }

  return (
    <SumaryDetailsSection>
      <div
        className={`summary ${isOpen ? 'summary--open' : ''}`}
        onClick={toggle}
        role="none"
        onKeyPress={(event) => (event.key === 'Enter' ? toggle : '')}
      >
        <div className="summary-content">
          <div className="row align-items-center">
            <div className="col-10">
              <span className="fs-16 lh-20 fw-600 text-grayscale--400">{summary}</span>
            </div>
            <div className="col-2">
              <div className={`arrow ${isOpen ? 'arrow--open' : ''}`} />
            </div>
          </div>
        </div>
        <div className={`details ${isOpen ? 'details--open' : 'details--closed'}`}>
          <div className="details-content">{children}</div>
        </div>
      </div>
    </SumaryDetailsSection>
  )
}

function returnDefaultProps() {
  return {
    summary: '',
    children: <div />,
  }
}

SummaryDetails.defaultProps = returnDefaultProps()

export default memo(SummaryDetails)
