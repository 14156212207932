import React, { useRef } from 'react'

import ZoomableImage from 'src/components/ZoomableImage'

type TextImageProps = {
  slug: string
  title?: string
  text: string
  image_url: string
  image_caption: string
  image_alignment: string
  image_alt?: string
  zoomEnabled: boolean
}

const TextImage = ({
  slug,
  title,
  text,
  image_url,
  image_caption,
  image_alignment,
  image_alt,
  zoomEnabled,
}: TextImageProps) => {
  const textImageRef = useRef<HTMLDivElement>(null)
  return (
    <div ref={textImageRef} id={slug} className="row my-0">
      <div className={`col-12 col-lg-6 ${image_alignment === 'right' ? 'order-1' : 'order-0'}`}>
        <figure>
          {zoomEnabled ? (
            <ZoomableImage zoomSrc={image_url} zoomAlt={image_alt} zoomMaxWidth="1200px">
              <img
                loading="lazy"
                className="w-100"
                src={image_url}
                alt={image_alt}
                title={image_caption}
              />
            </ZoomableImage>
          ) : (
            <img className="w-100" src={image_url} alt={image_alt} title={image_caption} />
          )}
          {image_caption && (
            <figcaption className="fs-14 lh-17 bg-grayscale--100 py-2 px-3 text-gray--800">
              {image_caption}
            </figcaption>
          )}
        </figure>
      </div>
      <div className="col-12 col-lg-6">
        {title && <h2>{title}</h2>}
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}

export default TextImage
