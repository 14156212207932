import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Btn = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #ff7a00;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;

  &:hover {
    text-decoration: none;
    color: #ff7a00;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 240px;
  }

  span {
    position: absolute;
    left: 16px;
  }
`
