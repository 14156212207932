import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { orange, grayscale, gray } from 'src/styles/colors'

export const CategoryHeader = styled.section`
  align-items: flex-end;
  background: ${orange.base};
  min-height: 150px;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 225px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 295px;
  }
`

export const Banner = styled.section`
  align-items: initial;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;

  @media (min-width: ${breakpoints.md}) {
    background-position: top right;
    align-items: center;
  }

  @media (min-width: ${breakpoints.lg}) {
  }

  @media (min-width: ${breakpoints.xl}) {
  }

  @media (min-width: 1440px) {
  }

  h1 {
    height: 142px;
    overflow: hidden;

    @media (min-width: ${breakpoints.md}) {
      height: auto;
    }
  }
`

export const SocialMediaFloat = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
`

export const ArticleContent = styled.article`
  margin: 35px 0;

  @media (min-width: ${breakpoints.lg}) {
    margin: 64px 0;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    color: ${grayscale[500]};
  }

  h3 {
    font-size: 20px;
    margin-bottom: 14px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 22px;
      margin-bottom: 1rem;
    }
  }

  h4 {
    font-size: 16px;
    margin-bottom: 12px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  h5 {
    font-size: 14px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }

  li {
    font-size: 14px;
    color: ${gray[800]};

    @media (min-width: ${breakpoints.xl}) {
      font-size: 16px;
    }
  }

  figcaption {
    font-size: 14px;
    background-color: ${grayscale[100]};
    padding: 8px 16px;
    color: ${gray[800]};
  }

  .negative-margin {
    @media (min-width: ${breakpoints.lg}) {
      margin-top: -240px;
    }
  }
`

export const BlockSection = styled.div`
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 30px;
  }
`
