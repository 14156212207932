import React, { useRef } from 'react'

// Style
import { BoxCitation } from './style'
// Images
import AspasCitation from './images/aspas.svg'

type TextProps = {
  author: string
  text: string
}

const Citation = ({ author, text }: TextProps) => {
  const textRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <hr />
      <BoxCitation ref={textRef} id={author}>
        <div>
          <img src={AspasCitation} alt="Citação" className="mr-4" />
          <div
            className="fw-500 lh-22 lh-md-26 mb-0 text-grayscale--500 fs-14 fs-md-18"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div className="pt-2">
          {author && (
            <span className="fw-400 lh-22 lh-md-26 text-grayscale--400 fs-14 fs-md-18 d-block">
              {author}
            </span>
          )}
        </div>
      </BoxCitation>
      <hr />
    </>
  )
}

export default Citation
