import styled from 'styled-components'
import breakpoints, { device } from 'src/styles/breakpoints'
import { borderRounded } from 'src/styles/radius'

type ModalContainerProps = {
  width?: string
}

export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ModalContainer = styled.div`
  padding: 1.5rem;
  background-color: #fff;
  position: relative;
  z-index: 100;
  min-width: 300px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  min-height: auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 340px;
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    border-radius: ${borderRounded['16']};
    top: inherit;
    transform: inherit;
    max-width: 750px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 1000px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: ${(props: ModalContainerProps) => props.width || 'auto'};
    min-height: auto;
  }
`

export const ModalInnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
  border-radius: ${borderRounded['6']};
  background: white;

  @media (min-width: ${breakpoints.md}) {
    max-height: 82vh;
  }
`

export const ModalBody = styled.div<{ noPadding: boolean }>`
  padding: ${(props) => (props.noPadding ? '0' : '16px')};
`
export const ModalCloseButton = styled.button`
  border: 2px solid;
  border-radius: ${borderRounded['50']};
  background: #fff;
  border-color: ${(props) => props.theme.colors.primary};
  padding: 0;
  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`
