import React from 'react'

import {
  ModalOverlay,
  ModalContainer,
  ModalInnerContainer,
  ModalBody,
  ModalCloseButton,
} from 'src/components/Modal/style'
import Icon from 'src/components/Icon'

const modalAreaId = 'modal-area'

export const ModalArea = React.memo((children) => <div id={modalAreaId}>{children}</div>)

type ModalProps = {
  children: React.ReactNode
  showCloseButton?: boolean
  onClose: () => void
  onClickOutside: () => void
  width?: string
  noPadding?: boolean
  openModal?: boolean
}
const Modal = ({
  children,
  width,
  showCloseButton = true,
  onClose,
  onClickOutside,
  noPadding = false,
  openModal,
}: ModalProps) => {
  const handleClickOutside = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClickOutside()
    }
  }

  return openModal ? (
    <ModalOverlay onClick={handleClickOutside}>
      <ModalContainer width={width}>
        {showCloseButton && (
          <div className="d-flex justify-content-end">
            <ModalCloseButton type="button" onClick={onClose}>
              <Icon width={20} height={20} directory="v2" icon="navigation/close" />
            </ModalCloseButton>
          </div>
        )}
        <ModalInnerContainer>
          <ModalBody noPadding={noPadding}>{children}</ModalBody>
        </ModalInnerContainer>
      </ModalContainer>
    </ModalOverlay>
  ) : (
    <></>
  )
}

export default Modal
