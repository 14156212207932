import React from 'react'

import Downl from 'inter-frontend-svgs/lib/v2/navigation/downl'

import { Btn } from './style'

type ButtonProps = {
  slug: string
  path: string
  title: string
}

const Button = ({ slug, path, title }: ButtonProps) => (
  <Btn id={slug} href={path} title={title}>
    <span>
      <Downl width={21} height={21} color="orange--base" />
    </span>
    {title}
  </Btn>
)

export default Button
