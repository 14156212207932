import React from 'react'

import Helmet from 'react-helmet'
// components
import SummaryDetails from 'src/components/SummaryDetails'
// Types
import { PostFaqTopic } from 'src/types/Post'

type FaqProps = {
  className?: string
  data: PostFaqTopic[]
  title?: string
  slug?: string
  columns?: {
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
}

const defaultCols = { sm: 1, md: 1, lg: 2, xl: 2 }

const FAQ = ({ className, data, columns, title, slug }: FaqProps) => {
  const cols = { ...defaultCols, ...columns }
  const parsedData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      data.map((item: PostFaqTopic) => ({
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      })),
    ],
  }

  return (
    <>
      <Helmet>
        <script id="structured-data-faq" type="application/ld+json">{`${JSON.stringify(
          parsedData,
        )}`}</script>
      </Helmet>
      <div className="row py-0 my-0">
        {title && (
          <div className="col-12 col-lg-6">
            <h2 id={slug}>{title}</h2>
          </div>
        )}
        {data.map((item: PostFaqTopic) => (
          <div
            key={item.question}
            className={`col-12 col-sm-${12 / cols.sm} col-md-${12 / cols.md} col-lg-${
              12 / cols.lg
            }`}
          >
            <SummaryDetails summary={item.question}>
              <div className={className} dangerouslySetInnerHTML={{ __html: item.answer }} />
            </SummaryDetails>
          </div>
        ))}
      </div>
    </>
  )
}

export default FAQ
