import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const SummaryBox = styled.div`
  padding: 24px 24px;
  border: 1px solid ${grayscale[200]};
  border-radius: 12px;
  width: 100% @media (min-width: ${breakpoints.md}) {
    padding: 32px 24px;
  }

  .sora {
    font-family: 'Sora';
  }
`
