import React from 'react'

import { SummaryBox } from './style'

type SummaryProps = {
  content: string
}

const Summary = ({ content }: SummaryProps) => (
  <SummaryBox>
    <h1 className="fs-20 lh-25 fs-md-32 lh-md-40 text-grayscale--500 fw-600 mt-0 mt-md-2 sora">
      Resumo
    </h1>
    <p className="fs-14 lh-22 fs-md-18 lh-md-25 text-grayscale--500 mb-0 mb-md-2">{content}</p>
  </SummaryBox>
)

export default Summary
