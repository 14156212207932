import styled from 'styled-components'

export const Container = styled.div`
  background: #f5f6fa;
  padding: 20px;
  bottom: 0;
  width: 100%;
  position: sticky;

  button {
    background: #ff7a00;
    border-radius: 8px;
    width: 100%;
    height: 28px;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
`
