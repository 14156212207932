import React from 'react'

// Style
import ZoomableImage from 'src/components/ZoomableImage'

import MediaStyle from './style'

type MediaProps = {
  type: string
  url: string
  caption: string
  alt?: string
  maxWidth?: string
  zoomEnabled: boolean
}

const Media = ({ type, url, caption, alt, maxWidth, zoomEnabled }: MediaProps) => {
  function setImage() {
    let image
    if (type === 'image') {
      if (zoomEnabled) {
        image = (
          <ZoomableImage zoomSrc={url} zoomAlt={alt} zoomMaxWidth="1200px">
            <img className="w-100" src={url} alt={alt} title={caption} />
          </ZoomableImage>
        )
      } else {
        image = <img className="w-100" src={url} alt={alt} title={caption} />
      }
    } else {
      image = (
        <iframe
          title="videos"
          src={url.replace('youtube.com', 'youtube-nocookie.com')}
          className="optanon-category-C0003"
          frameBorder="0"
          allow="autoplay"
          encrypted-media
          width="100%"
          allowFullScreen
          style={{
            maxWidth,
          }}
        />
      )
    }
    return image
  }

  return (
    <MediaStyle style={{ maxWidth }}>
      {setImage()}
      {caption && <figcaption style={{ maxWidth }}>{caption}</figcaption>}
    </MediaStyle>
  )
}

export default Media
